import SEO from "../components/SEO";
import React, {useEffect} from "react";
import GlobalStyle from "../components/GlobalStyle";
import Nav from "../components/Nav";
import styled from "styled-components";
import AppStoreLinks from "../components/AppStoreLinks";
import Analytics from "../utils/tracking";
import stockperksLogo from "../images/stockperks-logo.png";
import carnivalCorpLogo from "../images/clients/carnival/carnival-corp-logo.svg";
import aidaLogo from "../images/clients/carnival/aida-logo.png";
import carnivalLogo from "../images/clients/carnival/carnival-logo.png";
import costaLogo from "../images/clients/carnival/costa-logo.png";
import cunardLogo from "../images/clients/carnival/cunard-logo.svg";
import hollandLogo from "../images/clients/carnival/holland-logo.png";
import poLogo from "../images/clients/carnival/po-logo.svg";
import poAustralia from "../images/clients/carnival/po-australia-logo.png";
import princessLogo from "../images/clients/carnival/princess-logo.jpg";
import seabournLogo from "../images/clients/carnival/seabour-logo.png";
import {windowSize} from "../utils/responsive";

const CarnivalPage = () => {

  useEffect(() => {
    ;(async () => {
      await Analytics.track("Carnival x Stockperks Landing Page")
    })()
  }, [])

  return (
      <>
      <SEO
          title="Stockperks x Carnival"
          url={"/carnivalcorp"}
      />
      <GlobalStyle />
      <Nav siteTitle={"Stockperks x Carnival"} />
      <PageContent>
        <Header>
          <TopLogo src={carnivalCorpLogo} style={{marginBottom: 30}}/>
          <div style={{height: '100%', borderLeft: "1px solid black"}}></div>
          <a href={"https://www.stockperks.com"}>
            <TopLogo style={{marginTop:18 }} src={stockperksLogo}/>
          </a>
        </Header>
        <Body>
          <Content>
          <Title>Carnival Corp. is now on Stockperks!</Title>
            <RowCarnival>
              <p>Carnival Corporation has engaged Stockperks to implement a more automated and streamlined process for the processing of shareholder benefits. The Stockperks service is governed by Stockperks’ Privacy Policy, Terms of Service, and may be subject to other Stockperks policies and terms.  For information regarding Stockperks’ use and processing of your information, technical support, account setup, and all other questions regarding the use of Stockperks, please contact Stockperks at <a href={"mailto:carnivalcorp@stockperks.com"}>carnivalcorp@stockperks.com</a>.  For all other questions, please contact Carnival Corporation at the respective brand emails listed below.</p>
            </RowCarnival>

          <Paragraph style={{fontSize: 19, color: 'black'}}>Carnival Corporation & plc is pleased to extend the following benefit to our shareholders:</Paragraph>
          <h3>Shareholder Benefits</h3>
          <TableCarnival>
            <FirstRow>
              <th></th>
              <TableHeader>
                North America<br></br>Brands<br></br>
                <SpanBrands>Carnival Cruise Line*, Princess Cruises*, Holland America Line, Seabourn, Cunard*</SpanBrands>
              </TableHeader>
              <TableHeader>
                Continental<br></br>Europe Brands<br></br>
                <SpanBrands>Costa Cruises, AIDA Cruises</SpanBrands>
              </TableHeader>
              <TableHeader>
                United Kingdom<br></br>Brands<br></br>
                <SpanBrands>P&O Cruises (UK), Cunard*, Princess Cruises (UK)*</SpanBrands>
              </TableHeader>
              <TableHeader>
                Australia<br></br>Brands^<br></br>
                <SpanBrands>Princess Cruises*, Carnival Cruise Line*</SpanBrands>
              </TableHeader>
            </FirstRow>
            <tr>
              <TableD>Onboard credit per stateroom on sailings of 14 days or longer</TableD>
              <TableD>US $250</TableD>
              <TableD>€200</TableD>
              <TableD>£150</TableD>
              <TableD>A$250</TableD>
            </tr>
            <tr>
              <TableD>Onboard credit per stateroom on sailings of 7 to 13 days</TableD>
              <TableD>US $100</TableD>
              <TableD>€75</TableD>
              <TableD>£60</TableD>
              <TableD>A$100</TableD>
            </tr>
            <tr>
              <TableD>Onboard credit per stateroom on sailings of 6 days or less</TableD>
              <TableD>US $50</TableD>
              <TableD>€40</TableD>
              <TableD>£30</TableD>
              <TableD>A$50</TableD>
            </tr>
          </TableCarnival>
          <p style={{fontSize: 12, float: 'left', alignSelf: 'flex-start'}}>* The onboard credit for Carnival Cruise Line, Cunard and Princess Cruises is determined based on the operational currency onboard the vessel.</p>
          <p style={{fontSize: 12, float: 'left', alignSelf: 'flex-start'}}>^Effective March 10, 2025, guests holding reservations with P&O Cruises (Australia) must select Carnival Cruise Line when applying for the shareholder benefit.</p>

            <RowCarnival>
              <div className={"col-xs-7 col-sm-6 col-lg-1"}>
                <p>The benefit is available on sailings through December 31, 2025.  Applications to receive this benefit should be made at least three weeks prior to cruise departure date.</p>
                <p>This benefit is available to shareholders holding a minimum of 100 shares of Carnival Corporation or Carnival plc. Employees, travel agents cruising at travel agent rates or interline rates, tour conductors or anyone else cruising at a reduced-rate or on complimentary basis are excluded from this offer. This benefit is non-transferable, cannot be exchanged for cash and cannot be used for casino credits/charges and gratuities charged to your onboard account. We reserve the right, at our sole discretion, to change, modify or terminate this benefit or any of the terms and conditions relating to the benefit at any time without notice. Only one onboard credit per shareholder-occupied stateroom is permitted.</p>
                <p>Carnival Corporation & plc has engaged Stockperks to implement a more automated and streamlined process for the processing of shareholder benefits. Simply:</p>
                <ul>
                  <li>Download the app via the links below, or search for “Stockperks” in your app store</li>
                  <li>Once downloaded, create a Stockperks user profile</li>
                  <li>Then search for “Carnival” in the app, and select the cruise line you are traveling with</li>
                  <li>Click the blue button “Validate your portfolio” and you will be guided through the process of providing your CCL ownership details</li>
                  <li>Once confirmed you can then submit a claim for each eligible booking</li>
                </ul>
              <p>Thank you for being a loyal Carnival Corporation & plc shareholder and enjoy your cruise!</p>
                <h2>Redeem your Perk!</h2>
                <p>Download the Stockperks app today and create your profile. Then visit the Carnival profile and select the cruise line you're sailing on to confirm eligibility and claim your onboard credit!</p>

                <div style={{display: 'flex', justifyContent: 'center', marginBottom:30}}>
                  <AppStoreLinks />
                </div>

                <p>The Stockperks service is governed by Stockperks’ Privacy Policy, Terms of Service, and may be subject to other Stockperks policies and terms. For information regarding Stockperks’ use and processing of your information, technical support, account setup, and all other questions regarding the use of Stockperks, please contact Stockperks at  <a href={"mailto:carnivalcorp@stockperks.com"}>carnivalcorp@stockperks.com</a>. For all other questions, please contact Carnival Corporation & plc at the respective brand emails listed below.</p><br/>
              <ul>
                <li>AIDA: <a href={"mailto:kabinengruss@aida.de"}>kabinengruss@aida.de</a></li>
                <li>Carnival Cruise Lines: <a href={"mailto:shareholders@carnival.com"}>shareholders@carnival.com</a></li>
                <li>Costa Cruises: <a href={"mailto:shareholder@costa.it"}>shareholder@costa.it</a></li>
                <li>Cunard:</li>
                  <ul>
                    <li>UK: <a href={"mailto:guestservicesteam@cunard.co.uk"}>guestservicesteam@cunard.co.uk</a></li>
                    <li>Australia/ New Zealand: <a href={"mailto:shareholderbenefit@carnivalaustralia.com"}>shareholderbenefit@carnivalaustralia.com</a></li>
                    <li>North America: <a href={"mailto:SB@cunard.com"}>SB@cunard.com</a></li>
                  </ul>
                <li>Holland America Line: <a href={"mailto:Hal_Reservations@hollandamerica.com"}>Hal_Reservations@hollandamerica.com</a></li>
                <li>P&O Cruises (UK): <a href={"mailto:guestservices@pocruises.com"}>guestservices@pocruises.com</a></li>
                <li>P&O Australia: <a href={"mailto:shareholders@carnival.com"}>shareholders@carnival.com</a></li>
                <li>Princess Cruises: <a href={"mailto:sbpcl@princesscruises.com"}>sbpcl@princesscruises.com</a></li>
                <li>Seabourn: <a href={"mailto:seareservations@seabourn.com"}>seareservations@seabourn.com</a></li>
              </ul>
            </div>
            <div style={{minWidth: 300, textAlign: "center"}}>
              <a href={"https://www.aida.de"} rel={"nofollow"}><BrandLogo src={aidaLogo} /></a>
              <a href={"https://www.carnival.com"} rel={"nofollow"}><BrandLogo src={carnivalLogo} /></a>
              <a href={"https://www.costacruises.com"} rel={"nofollow"}><BrandLogo src={costaLogo} /></a>
              <a href={"https://www.cunard.com"} rel={"nofollow"}><BrandLogo src={cunardLogo} /></a>
              <a href={"https://www.hollandamerica.com"} rel={"nofollow"}><BrandLogo src={hollandLogo} /></a>
              <a href={"https://www.pocruises.com"} rel={"nofollow"}><BrandLogo src={poLogo} /></a>
              <a href={"https://www.pocruises.com.au"} rel={"nofollow"}><BrandLogo src={poAustralia} /></a>
              <a href={"https://www.princess.com"} rel={"nofollow"}><BrandLogo src={princessLogo} /></a>
              <a href={"https://www.seabourn.com"} rel={"nofollow"}><BrandLogo src={seabournLogo} /></a>
            </div>
          </RowCarnival>
        </Content>
      </Body>
      </PageContent>
</>
)
}

export default CarnivalPage

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-top: 95px;
`;

const TopLogo = styled.img`
  max-height: 120px;
  margin: 5px;
  @media (max-width: ${windowSize.medium}px) {
    height: 80px;
  }
`;

const BrandLogo = styled.img`
  max-width: 150px;
  margin: 20px 30px;
  height: auto;
`;

const Header = styled.div`
  display: flex;
  height: 100px;
  padding: 20px;
  flex-direction: row;
  align-items: center;
`;

const RowCarnival = styled.div`
  display: flex;
  @media (max-width: ${windowSize.medium}px) {
    display: block;
  }
`

const TableHeader = styled.th`
  text-transform: uppercase;
  font-size: 12px;
  vertical-align: top;
`

const TableCarnival = styled.table`
  border-bottom: 2px solid black;
`

const FirstRow = styled.tr`
  border-bottom: 2px solid black!important;
`

const SpanBrands = styled.span`
  font-weight: 300;
  padding-top: 5px;
  font-size: 12px;
  text-transform: none;
`

const TableD = styled.td`
  font-size: 12px;
  text-align: center;
`

const Title = styled.h1`
  font-family: Arial, Helvetica, serif;
  font-size: 35px;
  font-weight: 700;
  color: black;
  text-align: center;
`

const Paragraph = styled.p`
  font-family: Arial, Helvetica, serif;
  font-size: 18px;
  color: #7B7B7B;
`

const Body = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
`;